
import request from '@/axios/index';

// 我的收货地址列表
export function getaddresslist() {
    return request({
        url: '/api/app/v1/order/address/list',
        method: 'post',
    })
}
// 添加收货地址
export function addDdress(query) {
    return request({
        url: '/api/app/v1/order/address/add',
        method: 'post',
        data: query
    })
}
// 修改收货地址 

export function editAddress(query) {
    return request({
        url: '/api/app/v1/order/address/edit',
        method: 'post',
        data: query
    })
}
// 删除地址
export function addressDelete(query) {
    return request({
        url: '/api/app/v1/order/address/delete',
        method: 'post',
        data: query
    })
}
//  修改默认地址
export function setdefault(query) {
    return request({
        url: '/api/app/v1/order/address/set/default',
        method: 'post',
        data: query
    })
}


