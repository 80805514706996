<template>
  <div class="address">
    <div class="def" v-if="!showAdd">
      <div class="header">
        <div class="left" @click="goback">
          <van-icon name="arrow-left" class="icon" />
        </div>
        <div class="title">收货地址</div>
        <div class="btn" @click="changeActive">
          {{ active == 1 ? "编辑" : "完成" }}
        </div>
      </div>
      <div class="addrbox">
        <div class="addr_list">
          <van-radio-group v-model="defaultaddr">
            <div
              class="item"
              @click.stop="confirmAddress(item, $event)"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="top">
                <div class="left">
                  <div class="user">
                    <span class="userName">{{ item.name }}</span>
                    <span class="userPhone">{{ item.mobile }}</span>
                  </div>
                  <div class="addr">
                    {{ item.city }} {{ item.area }} {{ item.address }}
                  </div>
                </div>
                <div class="right">
                  <img
                    src="@/assets/all_Icon/drawable-xxhdpi/answer_item_edit.png"
                    class="img"
                    @click="editAddress(item)"
                  />
                </div>
              </div>
              <div class="bottom" v-if="active != 1">
                <div class="left">
                  <van-radio
                    :name="index"
                    label-disabled
                    @click.stop="setdef(item, $event)"
                    >默认地址</van-radio
                  >
                </div>
                <div class="right" @click.stop="deladdr(item, $event)">
                  删除
                </div>
              </div>
            </div>
          </van-radio-group>
        </div>
      </div>
      <div class="add" @click="add">+添加收货地址</div>
    </div>
    <div class="addEdit" v-if="showAdd">
      <div class="header">
        <div class="left">
          <van-icon @click="closeShow" name="arrow-left" class="icon" />
        </div>
        <div class="title">
          {{
            showAdd == true && edit == false ? "添加收货地址" : "修改收货地址"
          }}
        </div>
      </div>
      <div class="content">
        <div class="item">
          <van-cell-group>
            <van-field
              v-model.trim="addressObj.name"
              label="名字"
              placeholder="名字"
            />
          </van-cell-group>
        </div>
        <div class="item">
          <van-cell-group>
            <van-field
              v-model.trim="addressObj.mobile"
              label="手机号码"
              placeholder="手机号码"
            />
          </van-cell-group>
        </div>
        <div class="item">
          <van-cell-group>
            <van-field
              v-model.trim="city"
              label="地址"
              placeholder="地址"
              @click="toSelect"
            />
          </van-cell-group>
          <van-popup
            v-model="show"
            position="bottom"
            :style="{ height: '40%' }"
          >
            <van-area
              :area-list="areaList"
              @confirm="confirmFn"
              :columns-placeholder="['请选择', '请选择', '请选择']"
              title="选择地址"
            />
          </van-popup>
        </div>
        <div class="item">
          <van-cell-group>
            <van-field
              v-model.trim="addressObj.address"
              label="详细地址"
              placeholder="小区/门牌号"
            />
          </van-cell-group>
        </div>
        <div class="def">
          <div class="defText">设置默认地址</div>
          <van-switch v-model="addressObj.isDefault" class="switch" />
        </div>
      </div>
      <div class="saveBtn" @click="save">保存</div>
    </div>
  </div>
</template>

<script>
import {
  getaddresslist,
  addressDelete,
  setdefault,
  addDdress,
  editAddress,
} from "@/api/address.js";
import { areaList } from "@/assets/city.js";
export default {
  props: {},
  data() {
    return {
      active: 1,
      list: [],
      defaultaddr: null,
      showAdd: false,
      edit: false,
      editData: {},
      showPicker: false,
      addressObj: {
        address: "", //  详细地址
        area: "", // 地区
        areaId: "", //   地区Id
        city: "", //   城市
        cityId: "", //  城市ID
        mobile: "", //   手机号
        name: "", //  名字
        province: "", //  省
        provinceId: "", // 省ID
        isDefault: false,
        id: null,
      },
      pickerValue: null,
      show: false,
      areaList,
      city: "省、市、区、街道",
    };
  },
  created() {
    this.getaddress();
  },
  mounted() {},
  methods: {
    goback() {
      this.$router.go(-1);
    },
    toSelect() {
      this.show = true;
    },
    confirmAddress(val, event) {
      console.log(event.target.className);
      if (event.target.className.indexOf("van-icon") > -1) {
        return;
      }
      window.sessionStorage.setItem("addressObj", JSON.stringify(val));
      window.sessionStorage.setItem("addressFlag", "true");
      this.goback();
    },
    // 修改
    editAddress(item) {
      this.addressObj = {
        address: item.address, //  详细地址
        area: item.area, // 地区
        areaId: item.areaId, //   地区Id
        city: item.city, //   城市
        cityId: item.cityId, //  城市ID
        mobile: item.mobile, //   手机号
        name: item.name, //  名字
        province: item.province, //  省
        provinceId: item.provinceId, // 省ID
        isDefault: item.isDefault == 1 ? true : false,
        id: item.id,
      };
      this.city = item.province + " " + item.city + " " + item.area;
      this.showAdd = true;
      this.edit = true;
    },
    // 关闭 修改 增加
    closeShow() {
      this.showAdd = false;
      this.getaddress();
    },
    confirmFn(event) {
      this.show = false;
      let arr, SelectProvinceName, SelectCityName, SelectCountyName, ko;
      arr = event;
      console.log(arr);
      this.addressObj.province = arr[0].name; // 省
      this.addressObj.city = arr[1].name; // 市
      this.addressObj.area = arr[2].name; // 区
      ko = " "; //空格
      this.city =
        this.addressObj.province +
        ko +
        this.addressObj.city +
        ko +
        this.addressObj.area; //将选择的值赋值给city以显示在页面中
      this.addressObj.provinceId = arr[0].code;
      this.addressObj.cityId = arr[1].code;
      this.addressObj.areaId = arr[2].code;
    },
    cancelFn() {
      this.show = false;
      console.log("点击了取消按钮");
    },
    getaddress() {
      getaddresslist().then((res) => {
        res.data.data.map((item, index) => {
          if (item.isDefault == 1) {
            this.defaultaddr = index;
          }
        });
        this.list = res.data.data;
      });
    },
    deladdr(value) {
      this.$dialog
        .confirm({
          title: "确认删除？",
        })
        .then(() => {
          let formData = new FormData();
          formData.append("id", value.id);
          addressDelete(formData).then((res) => {
            if (res.data.code == 0) {
              this.$toast("删除成功");
              this.getaddress();
            }
          });
        })
        .catch(() => {});
    },
    save(val) {
      let reg = /^1[3456789]\d{9}$/;
      if (!this.addressObj.address) {
        this.$toast("请填写详细地址");
      }
      if (!this.addressObj.mobile) {
        this.$toast("请填写手机号");
      }
      if (!this.addressObj.name) {
        this.$toast("请填写姓名");
      }
      if (!reg.test(this.addressObj.mobile)) {
        this.$toast("手机号格式有误，请重新填写！");
      }
      let formData = new FormData();
      formData.append("address", this.addressObj.address);
      formData.append("area", this.addressObj.area);
      formData.append("areaId", this.addressObj.areaId);
      formData.append("city", this.addressObj.city);
      formData.append("cityId", this.addressObj.cityId);
      formData.append("province", this.addressObj.province);
      formData.append("provinceId", this.addressObj.provinceId);
      formData.append("isDefault", this.addressObj.isDefault ? 0 : 1);
      formData.append("name", this.addressObj.name);
      formData.append("mobile", this.addressObj.mobile);
      if (!this.edit) {
        //新增
        addDdress(formData).then((res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.showAdd = false;
            this.$toast("添加成功");
          } else {
            this.$toast(res.msg);
          }
        });
      } else {
        // 修改
        formData.append("id", this.addressObj.id);
        editAddress(formData).then((res) => {
          if (res.data.code == 0) {
            this.showAdd = false;
            this.edit = false;
            this.$toast("修改成功");
          } else {
            this.$toast(res.data.msg);
          }
        });
      }
      this.getaddress();
    },
    add() {
      this.showAdd = true;
      this.edit = false;
      this.city = "省、市、区、街道";
      this.addressObj = {
        address: "", //  详细地址
        area: "", // 地区
        areaId: "", //   地区Id
        city: "", //   城市
        cityId: "", //  城市ID
        mobile: "", //   手机号
        name: "", //  名字
        province: "", //  省
        provinceId: "", // 省ID
        isDefault: false,
      };
    },
    setdef(value, event) {
      let formData = new FormData();
      formData.append("id", value.id);
      setdefault(formData).then((res) => {
        this.$toast("设置默认成功");
      });
      let that = this;
      setTimeout(function () {
        that.getaddress();
      }, 1000);
    },
    changeActive() {
      if (this.active == 1) {
        this.active = 2;
      } else {
        this.active = 1;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 10px 10px;
  display: flex;
  .left {
    width: 10%;
    .icon {
      font-size: 24px;
      font-weight: 550;
    }
  }
  .title {
    width: 80%;
    text-align: center;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
  }
  .btn {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5f7dff;
  }
}
.addrbox {
  background: #f8f8f8;
  min-height: 84vh;
  .addr_list {
    padding: 30px 14px 30px;
    .item {
      padding: 16px 13px;
      border-radius: 5px;
      background: #fff;
      margin-bottom: 10px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          width: 90%;
          .user {
            margin-bottom: 5px;
            .userName {
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #141414;
              line-height: 23px;
            }
            .userPhone {
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 18px;
              margin-left: 10px;
            }
          }
        }
        .right {
          width: 10%;
          display: flex;
          justify-content: flex-end;
          .img {
            width: 16px;
          }
        }
      }
      .bottom {
        border-top: 1px solid #dddddd;
        margin-top: 8px;
        padding: 13px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
        }
        .right {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #777777;
        }
      }
    }
  }
}
.add {
  background: #5f7dff;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  width: 90%;
  padding: 9px 0;
  margin: 14px auto;
  border-radius: 5px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.addEdit {
  border-top: 1px solid #dddddd;
  /deep/ .van-field__label {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 550;
    color: #141414;
  }
  .van-field {
    padding: 15px 16px;
  }
  .def {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px;
    margin-top: 14px;
    .defText {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 550;
      color: #141414;
    }
  }
  .saveBtn {
    background: #5f7dff;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    width: 90%;
    padding: 9px 0;
    margin: 14px auto;
    border-radius: 5px;
    margin-top: 30%;
  }
}
</style>
